<template>
  <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
    <template v-if="internetConnection">
      <loader-page v-if="isLoading"></loader-page>
      <template v-else>
        <div class="uk-grid-small" uk-grid>
          <!-- Search category -->
          <div v-if="activeTab === 'category'" class="uk-width-1-5">
            <form @submit.prevent="searchCategory">
              <div class="uk-inline">
                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                <input
                    class="uk-input uk-width-1-1"
                    type="text"
                    placeholder="Search category"
                    :disabled="disabledFilterAndCreateNewJobPreferenceCategory"
                    v-model="metaCategoryList.name"
                >
              </div>
            </form>
          </div>
          <!-- End search category -->

          <!-- Search Job Preference -->
          <div v-if="activeTab === 'jobPreference'" class="uk-width-1-5">
            <form @submit.prevent="searchJobPreference">
              <div class="uk-inline">
                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                <input
                    class="uk-input uk-width-1-1"
                    type="text"
                    placeholder="Search category"
                    v-model="jobPreferenceName"
                >
              </div>
            </form>
          </div>
          <!-- End search job preference -->

          <div class="uk-width-1-5"></div>
          <div class="uk-width-1-5"></div>
          <div class="uk-width-1-5"></div>

          <!-- Create new category -->
          <div v-if="consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech']) && activeTab === 'category'" class="uk-width-1-5">
            <button
                type="button"
                class="uk-button uk-border-rounded uk-width-1-1"
                :class="[disabledFilterAndCreateNewJobPreferenceCategory ? 'uk-button-default' : 'uk-button-primary']"
                :disabled="disabledFilterAndCreateNewJobPreferenceCategory"
                @click="showModalCreateNewCategory"
            >Create New</button>
          </div>
          <!-- End create new category -->

          <!-- Create new job preference -->
          <div v-if="consist(user_cred.roles, ['qwe123','superadmin-product','superadmin-tech']) && activeTab === 'jobPreference'" class="uk-width-1-5">
            <button
                type="button"
                class="uk-button uk-button-primary uk-border-rounded uk-width-1-1"
                @click="showModalCreateNewJobPreference"
            >Create New</button>
          </div>
          <!-- End create new job preference -->
        </div>

        <!-- Tab -->
        <div class="tab-menu">
          <button
              type="button"
              class="tab-menu__button"
              :class="[activeTab === 'category' ? 'tab-menu__button-active' : '']"
              @click="setActiveTabMenu({ tab: 'category' })"
          >Category</button>
          <button
              type="button"
              class="tab-menu__button"
              :class="[activeTab === 'jobPreference' ? 'tab-menu__button-active' : '']"
              @click="setActiveTabMenu({ tab: 'jobPreference' })"
          >Job Preference</button>
        </div>
        <!-- End tab -->

        <!-- Tab Content -->
        <category-tab
            v-if="activeTab === 'category'"
            :disabled-change-page-action="disabledFilterAndCreateNewJobPreferenceCategory"
            :category-list-table-loading="categoryListTableLoading"
            :cancel-reorder-category-list-button="cancelReorderCategoryListButton"
            :save-reorder-category-list-button="saveReorderCategoryListButton"
            :reorder-mode="reorderMode"
            :category-data="category"
            @enable-reorder-mode="enableReorderMode"
            @cancel-reorder-category-list="cancelReorderCategoryList"
            @save-reorder-category-list="saveReorderCategoryList"
            @edit-category="showModalEditCategory"
            @delete-category="showModalDeleteCategory"
            @change-limit="changeLimitMetaCategoryList"
            @change-page="changePageCategoryList"
        ></category-tab>
        <job-preference-tab
            v-if="activeTab === 'jobPreference'"
            :category-options="category_options"
            :job-preference-list-table-loading="jobPreferenceListTableLoading"
            :cancel-reorder-job-preference-list-button="cancelReorderjobPreferenceListButton"
            :save-reorder-job-preference-list-button="saveReorderjobPreferenceListButton"
            :job-preference-reorder-mode="jobPreferenceReorderMode"
            :job-preference-data="jobPreference"
            @enable-job-preference-reorder-mode="enableJobPreferenceReorderMode"
            @cancel-reorder-job-preference-list="cancelReorderJobPreferenceList"
            @save-reorder-job-preference-list="saveReorderJobPreferenceList"
            @edit-job-preference="showModalEditJobPreference"
            @delete-job-preference="showModalDeleteJobPreference"
            @import-job-preference="showModalImportJobPreference"
            @download-template-job-preference="downloadTemplateJobPreference"
            @change-limit="changeLimitMetaJobPreferenceList"
            @change-page="changePageJobPreferenceList"
        ></job-preference-tab>
        <!-- End tab content -->

        <!-- Category feature modal box -->
        <div id="create-or-edit-category-modal-box" class="uk-flex-top create-or-edit-category-modal-box" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" @click="resetSelectedCategoryData" uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">
                {{ createOrEditCategoryType === 'create' ? 'Create New Category' : 'Edit Category' }}
              </h2>
            </div>
            <form @submit.prevent="createOrEditCategory">
              <div class="uk-padding-small">
                <div class="uk-grid-small" uk-grid>
                  <div class="uk-width-1-1">
                    <label for="categoryName" class="uk-form-label">Category Name <span class="uk-text-danger">*</span></label>
                    <input
                        id="categoryName"
                        type="text"
                        class="uk-input uk-border-rounded"
                        v-model="selectedCategoryData.name"
                    >
                  </div>
                </div>
              </div>
              <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                <button
                    v-if="createOrEditCategorySaveButtonLoading"
                    type="button"
                    class="uk-button uk-button-default uk-border-rounded"
                    disabled
                >
                  <span uk-spinner="ratio: 0.5"></span>
                  <span class="uk-margin-small-left">Loading</span>
                </button>
                <button
                    v-else
                    type="submit"
                    class="uk-button uk-border-rounded"
                    :class="[disabledCreateOrEditCategorySaveButton === true ? 'uk-button-default' : 'uk-button-primary']"
                    :disabled="disabledCreateOrEditCategorySaveButton === true"
                >Save</button>
              </div>
            </form>
          </div>
        </div>

        <div id="confirmation-delete-category-modal-box" class="uk-flex-top confirmation-delete-category-modal-box" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" @click="resetSelectedCategoryData" uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">Are you sure to delete this data ?</h2>
            </div>
            <div class="uk-modal-body">
              <p>Category : {{ selectedCategoryData.name || '-' }}</p>
            </div>
            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
              <button
                  v-if="deleteCategoryButtonLoading"
                  type="button"
                  class="uk-button uk-button-default uk-border-rounded"
                  disabled
              >
                <span uk-spinner="ratio: 0.5"></span>
                <span class="uk-margin-small-left">Loading</span>
              </button>
              <button
                  v-else
                  type="button"
                  class="uk-button uk-border-rounded"
                  :class="[disabledDeleteCategoryButton === true ? 'uk-button-default' : 'uk-button-danger']"
                  :disabled="disabledDeleteCategoryButton === true"
                  @click="doDeleteCategory"
              >Delete</button>
            </div>
          </div>
        </div>
        <!-- End category feature modal box -->

        <!-- Job preference feature modal box -->
        <div id="create-or-edit-job-preference-modal-box" class="uk-flex-top create-or-edit-job-preference-modal-box" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" @click="resetSelectedJobPreferenceData" uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">
                {{ createOrEditJobPreferenceType === 'create' ? 'Create New Job Preference' : 'Edit Job Preference' }}
              </h2>
            </div>
            <form @submit.prevent="createOrEditJobPreference">
              <div class="uk-padding-small">
                <div class="uk-grid-small" uk-grid>
                  <div class="uk-width-1-1">
                    <label for="JobPreferenceName" class="uk-form-label">Job Preference Name <span class="uk-text-danger">*</span></label>
                    <input
                        name="job_preference_name"
                        id="JobPreferenceName"
                        type="text"
                        class="uk-input uk-border-rounded"
                        v-model="selectedJobPreferenceData.name"
                        v-validate="'required'"
                    >
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('job_preference_name')">{{ errors.first('job_preference_name') }}</span>
                  </div>
                  <div class="uk-width-1-1">
                    <label for="SeoUrl" class="uk-form-label">SEO URL<span class="uk-text-danger">*</span></label>
                    <input
                        name="seo_url"
                        id="SeoUrl"
                        type="text"
                        class="uk-input uk-border-rounded"
                        v-model="selectedJobPreferenceData.seo_url"
                        v-validate="'required'"
                    >
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('job_preference_name')">{{ errors.first('seo_url') }}</span>
                  </div>
                  <div class="uk-width-1-1">
                    <label for="categoryName" class="uk-form-label">Select Category <b class="uk-text-danger">*</b></label>
                    <multiselect
                        v-model="selectedJobPreferenceData.job_preference_category_id"
                        placeholder="Type to search category name"
                        label="name"
                        name="category"
                        id="categoryName"
                        track-by="_id"
                        :options="category_options"
                        :multiple="false"
                        :close-on-select="true"
                        :searchable="true"
                        :loading='isFetching'
                        :internal-search="false"
                        :options-limit="100"
                        @search-change="setCategoryValues"
                        v-validate="'required'"
                    >
                    </multiselect>
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('category')">{{ errors.first('category') }}</span>
                  </div>
                  <div class="uk-width-1-1">
                    <label class="uk-form-label">Description <span class="uk-text-danger">*</span></label>
                    <div class="uk-width-1-1 uk-inline">
                                <textarea
                                    :maxlength="descriptionLength"
                                    name="description"
                                    rows="3"
                                    class="uk-textarea"
                                    type="text"
                                    v-model="selectedJobPreferenceData.description"
                                    v-validate="'required'"
                                >
                                </textarea>
                    </div>
                    <div uk-grid>
                      <div class="uk-inline uk-width-1-2">
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('description')">{{ errors.first('description') }}</span>
                      </div>
                      <div class="uk-width-1-2 uk-text-right">
                        <span class="uk-text-small">{{ selectedJobPreferenceData.description.length }}/{{ descriptionLength }} Characters</span>
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-1">
                    <label for="thumbnail" class="uk-form-label">Select Thumbnail <span class="uk-text-danger">*</span></label><br>
                    <img
                        v-if="selectedJobPreferenceData.image && selectedJobPreferenceData.image !== ''"
                        :src="`${selectedJobPreferenceData.image}`"
                        alt=""
                        class="uk-margin-bottom"
                        width="200"
                    >
                    <button class="uk-button uk-button-primary uk-border-rounded uk-width-1-3 uk-flex uk-flex-middle uk-flex-center" type="button" @click="openFileThumbnail">
                                <span>
                                    <img :src="`${images}/cloud-download-white.svg`" alt="Cloud download icon">
                                </span>
                      <span class="uk-margin-small-left">
                                    {{ ThumbnailName }}
                                </span>
                    </button>
                    <input
                        id="thumbnail"
                        type="file"
                        ref="thumbnail"
                        accept=".png,.jpg"
                        style="display: none"
                        @change="UploadThumbnail($event)"
                    >
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('thumbnail')">{{ errors.first('thumbnail') }}</span>
                    <span class="uk-text-small" v-show="!errors.has('thumbnail')">Max file size 50kb</span>
                  </div>
                  <div class="uk-width-1-1">
                    <label class="uk-form-label">
                      <input
                          class="uk-checkbox uk-margin-small-right"
                          name="default"
                          type="checkbox"
                          v-model="selectedJobPreferenceData.default"
                      >
                      Default
                    </label>
                  </div>
                </div>
              </div>
              <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                <button
                    v-if="createOrEditjobPreferenceSaveButtonLoading"
                    type="button"
                    class="uk-button uk-button-default uk-border-rounded"
                    disabled
                >
                  <span uk-spinner="ratio: 0.5"></span>
                  <span class="uk-margin-small-left">Loading</span>
                </button>
                <button
                    v-else
                    type="submit"
                    class="uk-button uk-border-rounded"
                    :class="[disabledCreateOrEditJobPreferenceSaveButton === true ? 'uk-button-default' : 'uk-button-primary']"
                    :disabled="disabledCreateOrEditJobPreferenceSaveButton === true"
                >Save</button>
              </div>
            </form>
          </div>
        </div>

        <div id="confirmation-delete-job-preference-modal-box" class="uk-flex-top confirmation-delete-job-preference-modal-box" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" @click="resetSelectedJobPreferenceData" uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">Are you sure to delete this data ?</h2>
            </div>
            <div class="uk-modal-body">
              <p>
                Job Preference Name : {{ selectedJobPreferenceData.name || '-' }}<br>
                Category : {{ selectedJobPreferenceData.job_preference_category_id.name || '-' }}<br>
                Description : {{ selectedJobPreferenceData.description || '-' }}<br>
                Default : {{ selectedJobPreferenceData.default || '-' }}
              </p>
            </div>
            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
              <button
                  v-if="deletejobPreferenceButtonLoading"
                  type="button"
                  class="uk-button uk-button-default uk-border-rounded"
                  disabled
              >
                <span uk-spinner="ratio: 0.5"></span>
                <span class="uk-margin-small-left">Loading</span>
              </button>
              <button
                  v-else
                  type="button"
                  class="uk-button uk-border-rounded"
                  :class="[disabledDeleteJobPreferenceButton === true ? 'uk-button-default' : 'uk-button-danger']"
                  :disabled="disabledDeleteJobPreferenceButton === true"
                  @click="doDeleteJobPreference"
              >Delete</button>
            </div>
          </div>
        </div>
        <!-- End job preference feature modal box -->

        <!-- Import job preference feature modal box -->
        <div id="import-job-preference-modal-box" class="uk-flex-top import-job-preference-modal-box" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" @click="resetBulkUploadData" uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title uk-text-center">
                {{ 'Import Data Binding' }}
              </h2>
            </div>
            <form @submit.prevent="createBulkUpload">
              <div class="uk-padding-small">
                <div class="uk-grid-small" uk-grid>
                  <!-- ROW 1-->
                  <div class="uk-width-1-2">
                    <label for="JobPreferenceName" class="uk-form-label uk-text-middle">Job Preference Name</label>
                  </div>
                  <div class="uk-width-1-2">
                    <input
                        disabled
                        name="job_preference_name"
                        id="JobPreferenceName"
                        type="text"
                        class="uk-input uk-border-rounded"
                        v-model="bulkUploadData.jobPreferenceName"
                        v-validate="'required'"
                    >
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('job_preference_name')">{{ errors.first('job_preference_name') }}</span>
                  </div>
                  <!-- ROW 2-->
                  <div class="uk-width-1-2">
                    <label for="JobPreferenceId" class="uk-form-label uk-text-middle">Job Preference ID</label>
                  </div>
                  <div class="uk-width-1-2">
                    <input
                        disabled
                        name="job_preference_id"
                        id="JobPreferenceId"
                        type="text"
                        class="uk-input uk-border-rounded"
                        v-model="bulkUploadData.jobPreferenceId"
                        v-validate="'required'"
                    >
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('job_preference_id')">{{ errors.first('job_preference_id') }}</span>
                  </div>
                  <!-- ROW 3-->
                  <div class="uk-width-1-2">
                    <label class="uk-form-label uk-text-middle">Select file to import</label><br>
                  </div>
                  <div class="uk-width-1-2">
                    <button class="uk-button uk-button-primary uk-border-rounded uk-width-1-1 uk-flex uk-flex-middle uk-flex-center" type="button" @click="openFileUpload">
                                  <span>
                                      <img :src="`${images}/cloud-download-white.svg`" alt="Cloud download icon">
                                  </span>
                      <span class="uk-margin-small-left">
                                          {{ UploadFileName }}
                                  </span>
                      <input
                          id="uploadfile"
                          type="file"
                          ref="upload_file"
                          accept=".xls,.xlsx"
                          style="display: none"
                          @change="UploadFile"
                      >
                    </button>
                    <span class="uk-text-small uk-text-danger" v-show="errors.has('thumbnail')">{{ errors.first('thumbnail') }}</span>
                  </div>
                </div>
              </div>
              <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                <button
                    v-if="importJobPreferenceSaveButtonLoading"
                    type="button"
                    class="uk-button uk-button-default uk-border-rounded"
                    disabled
                >
                  <span uk-spinner="ratio: 0.5"></span>
                  <span class="uk-margin-small-left">Loading</span>
                </button>
                <button
                    v-else
                    type="submit"
                    class="uk-button uk-border-rounded"
                    :class="[disabledBulkUploadSaveButton === true ? 'uk-button-default' : 'uk-button-primary']"
                    :disabled="disabledBulkUploadSaveButton === true"
                >Save</button>
              </div>
            </form>
          </div>
        </div>
        <!-- End import job preference feature modal box -->
      </template>
    </template>
    <no-internet-connection v-else></no-internet-connection>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';
import { excelDownloader, excelReader } from "@/utils/helper";
export default {
    name: 'JobPreference',
    components: {
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        NoInternetConnection: () => import('@/components/globals/NoInternetConnection'),
        CategoryTab: () => import('@/components/globals/JobPreference/CategoryTab'),
        JobPreferenceTab: () => import('@/components/globals/JobPreference/JobPreferenceTab')
    },
    data() {
        return {
            // region For global
            images: PREFIX_IMAGE,
            descriptionLength: 100,
            user_cred: getUserLogin(),
            internetConnection: true,
            isLoading: false,
            activeTab: 'category',
            category_options: [],
            isFetching: false,
            // endregion for global
            // region For category feature,
            categoryListTableLoading: false,
            createOrEditCategorySaveButtonLoading: false,
            deleteCategoryButtonLoading: false,
            cancelReorderCategoryListButton: false,
            saveReorderCategoryListButton: false,
            disabledFilterAndCreateNewJobPreferenceCategory: false,
            category: {
                docs: [],
                totalDocs: 0,
                pagingCounter: 0,
                meta: {
                    limit: 10,
                    page: 1,
                    name: ''
                }
            },
            createOrEditCategoryType: 'create',
            reorderMode: false,
            tempSelectedCategoryData: {
                id: null,
                name: '',
                order: null
            },
            selectedCategoryData: {
                id: null,
                name: '',
                order: null
            },
            metaCategoryList: {
                limit: 10,
                page: 1,
                name: ''
            },
            // endregion for category feature
            // region Job Preference
            ThumbnailName: 'Upload Image',
            jobPreferenceListTableLoading: false,
            createOrEditjobPreferenceSaveButtonLoading: false,
            deletejobPreferenceButtonLoading: false,
            cancelReorderjobPreferenceListButton: false,
            saveReorderjobPreferenceListButton: false,
            jobPreference: {
                docs: [],
                totalDocs: 0,
                meta: {
                    limit: 10,
                    page: 1,
                    name: ''
                }
            },
            jobPreferenceName: null,
            createOrEditJobPreferenceType: 'create',
            jobPreferenceReorderMode: false,
            tempSelectedJobPreferenceData: {
                id: null,
                name: '',
                seo_url: '',
                job_preference_category_id: {},
                description: '',
                default: false,
                image: null,
                order: null
            },
            selectedJobPreferenceData: {
                id: null,
                name: '',
                seo_url: '',
                job_preference_category_id: {},
                description: '',
                default: false,
                image: null,
                order: null
            },
            metaJobPreferenceList: {
                limit: 10,
                page: 1,
                name: ''
            },
            // endregion Job Preference
            // region Bulk Upload
            importJobPreferenceSaveButtonLoading: false,
            UploadFileName: 'Upload File',
            bulkUploadData: {
                jobPreferenceName: null,
                jobPreferenceId: null,
                fileData: null,
            },
            // endregion
        };
    },
    computed: {
        // category
        disabledCreateOrEditCategorySaveButton() {
            let disabled = true;
            if (this.createOrEditCategoryType === 'create') {
                if (this.selectedCategoryData.name === '') {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else if (this.createOrEditCategoryType === 'edit') {
                if (
                    this.selectedCategoryData.id === null ||
            this.selectedCategoryData.name === '' ||
            this.removeSpaceFromString({ string: this.selectedCategoryData.name }).toLowerCase() === this.removeSpaceFromString({ string: this.tempSelectedCategoryData.name }).toLowerCase()
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            }
            return disabled;
        },
        disabledDeleteCategoryButton() {
            let disabled = true;
            if (
                this.selectedCategoryData.id === null ||
          this.selectedCategoryData.name === ''
            ) {
                disabled = true;
            } else {
                disabled = false;
            }
            return disabled;
        },
        // job preference
        disabledCreateOrEditJobPreferenceSaveButton() {
            let disabled = true;
            if (this.createOrEditJobPreferenceType === 'create') {
                disabled = this.selectedJobPreferenceData.name === '' ||
            this.selectedJobPreferenceData.seo_url === '' ||
            this.selectedJobPreferenceData.job_preference_category_id === null ||
            this.selectedJobPreferenceData.job_preference_category_id === undefined ||
            this.selectedJobPreferenceData.job_preference_category_id === {} ||
            this.selectedJobPreferenceData.description === '' ||
            this.selectedJobPreferenceData.image === null ||
            this.selectedJobPreferenceData.image === 'null' ||
            this.removeSpaceFromString({
                string: this.selectedJobPreferenceData.name
            }).toLowerCase() === this.removeSpaceFromString({string: this.tempSelectedJobPreferenceData.name}).toLowerCase() ||
            this.removeSpaceFromString({
                string: this.selectedJobPreferenceData.seo_url
            }).toLowerCase() === this.removeSpaceFromString({string: this.tempSelectedJobPreferenceData.seo_url}).toLowerCase();
            } else if (this.createOrEditJobPreferenceType === 'edit') {
                disabled = this.selectedJobPreferenceData.name === '' ||
            this.selectedJobPreferenceData.seo_url === '' ||
            this.selectedJobPreferenceData.job_preference_category_id === null ||
            this.selectedJobPreferenceData.job_preference_category_id === undefined ||
            this.selectedJobPreferenceData.job_preference_category_id === {} ||
            this.selectedJobPreferenceData.description === '' ||
            this.selectedJobPreferenceData.image === null ||
            this.selectedJobPreferenceData.image === 'null';
            }
            return disabled;
        },
        disabledDeleteJobPreferenceButton() {
            let disabled = true;
            if (
                this.selectedJobPreferenceData.id === null ||
          this.selectedJobPreferenceData.name === ''
            ) {
                disabled = true;
            } else {
                disabled = false;
            }
            return disabled;
        },
        // bulk upload
        disabledBulkUploadSaveButton() {
            let disabled = true;
            disabled = this.bulkUploadData.jobPreferenceName === '' ||
          this.bulkUploadData.jobPreferenceId === '' ||
          this.bulkUploadData.fileData === null;
            return disabled;
        },
    },
    watch: {
        'selectedCategoryData.name'(val) {
            this.name = val.replace(/[^ \w]/g, "").trim();
        },
        // validation white space for seo url
        'selectedJobPreferenceData.seo_url'(val) {
            if (val.trim().length === 0) {
                this.selectedJobPreferenceData.seo_url = val.replace(/[^ \w]/g, "").trim();
            } else {
                this.selectedJobPreferenceData.seo_url = val.replace(/\s\s+/g, ' ');
            }
        },
        // validation white space for description
        'selectedJobPreferenceData.description'(val) {
            if (val.trim().length === 0) {
                this.selectedJobPreferenceData.description = val.replace(/[^ \w]/g, "").trim();
            } else {
                this.selectedJobPreferenceData.description = val.replace(/\s\s+/g, ' ');
            }
        },
        // validation white space for name
        'selectedJobPreferenceData.name'(val) {
            if (val.trim().length === 0) {
                this.selectedJobPreferenceData.name = val.replace(/[^ \w]/g, "").trim();
            } else {
                this.selectedJobPreferenceData.name = val.replace(/\s\s+/g, ' ');
            }
        },
        // category
        async metaCategoryList() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.categoryListTableLoading = true;
                    await this.setCategory();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.categoryListTableLoading = false;
            }
        },
        // job preference
        async metaJobPreferenceList() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.jobPreferenceListTableLoading = true;
                    await this.setJobPreference();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.jobPreferenceListTableLoading = false;
            }
        }
    },
    async mounted() {
        try {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.isLoading = true;
                await this.setFirstActiveTab();
                await this.setCategory();
                await this.setCategoryValues();
                await this.setJobPreference();
            } else {
                this.internetConnection = false;
            }
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.create-or-edit-category-modal-box')) window.UIkit.modal('.create-or-edit-category-modal-box').$destroy(true);
        if (window.UIkit.modal('.create-or-edit-job-preference-modal-box')) window.UIkit.modal('.create-or-edit-job-preference-modal-box').$destroy(true);
        if (window.UIkit.modal('.confirmation-delete-job-preference-modal-box')) window.UIkit.modal('.confirmation-delete-job-preference-modal-box').$destroy(true);
        if (window.UIkit.modal('.confirmation-delete-category-modal-box')) window.UIkit.modal('.confirmation-delete-category-modal-box').$destroy(true);
    },
    methods: {
        ...mapActions({
            // Global
            uploadImage: 'file/uploadImage',
            // Job Preference Category
            getJobPreferenceCategoryList: 'job_preference_category/getJobPreferenceCategoryList',
            createJobPreferenceCategory: 'job_preference_category/createJobPreferenceCategory',
            editJobPreferenceCategory: 'job_preference_category/editJobPreferenceCategory',
            reorderCategoryList: 'job_preference_category/reorderCategoryList',
            deleteJobPreferenceCategory: 'job_preference_category/deleteJobPreferenceCategory',
            // Job Preference
            getJobPreferenceList: 'job_preference/getJobPreferenceLists',
            createJobPreference: 'job_preference/createJobPreference',
            editJobPreference: 'job_preference/editJobPreference',
            // Bulk Upload
            createBulkUploadJobPreference: 'job_preference/createBulkUpload',
            deleteJobPreference: 'job_preference/deleteJobPreference',
            reorderJobPreferenceList: 'job_preference/reorderJobPreferenceList',
        }),
        // region For global
        checkFile(file) {
            if (file[0].size > 55000) { // not over 50kb
                notificationDanger('file tidak boleh lebih dari 50kb');
                return false;
            } else {
                return true;
            }
        },
        async setFirstActiveTab() {
            try {
                if (!this.$route.query.tab) {
                    await this.setActiveTabMenu({ tab: 'category' });
                } else {
                    await this.setActiveTabMenu({ tab: this.$route.query.tab });
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setActiveTabMenu({ tab }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    if (this.$route.query.tab !== tab) {
                        await this.setQueryURL({ tab });
                    }
                    this.activeTab = this.$route.query.tab;
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setQueryURL({ tab }) {
            try {
                await this.$router.replace({
                    path: '/admin/job-preference',
                    query: {
                        tab
                    }
                });
            } catch (error) {
                notificationDanger(error);
            }
        },
        removeSpaceFromString({ string }) {
            return string.replace(/\s/g, '');
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        // endregion for global
        // region For category feature
        async setCategoryValues(query) {
            this.isFetching = true;
            const options = await this.getJobPreferenceCategoryList({ name: query });
            this.category_options = options.result.docs;
            this.isFetching = false;
        },
        async setCategory() {
            try {
                const response = await this.getJobPreferenceCategoryList(this.metaCategoryList);
                if (response && response.status === 'OK') {
                    this.category = response.result;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async searchCategory() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.categoryListTableLoading = true;
                    await this.setCategory();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.categoryListTableLoading = false;
            }
        },
        async showModalCreateNewCategory() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.resetTempSelectedCategoryData();
                    this.resetSelectedCategoryData();
                    this.createOrEditCategoryType = 'create';
                    await window.UIkit.modal('#create-or-edit-category-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async enableReorderMode() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.reorderMode = true;
                    this.disabledFilterAndCreateNewJobPreferenceCategory = true;
                    this.metaCategoryList.name = '';
                    this.cancelReorderCategoryListButton = true;
                    this.categoryListTableLoading = true;
                    await this.setCategory();
                    this.categoryListTableLoading = false;
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                this.reorderMode = false;
                this.disabledFilterAndCreateNewJobPreferenceCategory = false;
                notificationDanger(error);
            } finally {
                this.cancelReorderCategoryListButton = false;
                this.categoryListTableLoading = false;
            }
        },
        async cancelReorderCategoryList() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.cancelReorderCategoryListButton = true;
                    this.categoryListTableLoading = true;
                    await this.setCategory();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.cancelReorderCategoryListButton = false;
                this.categoryListTableLoading = false;
                this.reorderMode = false;
                this.disabledFilterAndCreateNewJobPreferenceCategory = false;
            }
        },
        async saveReorderCategoryList(payload) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.saveReorderCategoryListButton = true;
                    const response = await this.reorderCategoryList(payload);
                    if (response && response.status === 'OK') {
                        this.categoryListTableLoading = true;
                        await this.setCategory();
                        notificationSuccess('Category list reordered successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.saveReorderCategoryListButton = false;
                this.categoryListTableLoading = false;
                this.reorderMode = false;
                this.disabledFilterAndCreateNewJobPreferenceCategory = false;
            }
        },
        async showModalEditCategory(payload) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.resetTempSelectedCategoryData();
                    this.resetSelectedCategoryData();
                    this.createOrEditCategoryType = 'edit';
                    if (payload) {
                        this.fillTempSelectedCategoryData({ id: payload._id, name: payload.name, order: payload.order });
                        this.fillSelectedCategoryData({ id: payload._id, name: payload.name, order: payload.order });
                    }
                    await window.UIkit.modal('#create-or-edit-category-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showModalDeleteCategory(payload) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.resetTempSelectedCategoryData();
                    this.resetSelectedCategoryData();
                    if (payload) {
                        this.fillSelectedCategoryData({ id: payload._id, name: payload.name, order: payload.order });
                    }
                    await window.UIkit.modal('#confirmation-delete-category-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        fillTempSelectedCategoryData({ id, name, order }) {
            this.tempSelectedCategoryData.id = id;
            this.tempSelectedCategoryData.name = name;
            this.tempSelectedCategoryData.order = order;
        },
        fillSelectedCategoryData({ id, name, order }) {
            this.selectedCategoryData.id = id;
            this.selectedCategoryData.name = name;
            this.selectedCategoryData.order = order;
        },
        resetTempSelectedCategoryData() {
            this.tempSelectedCategoryData.id = null;
            this.tempSelectedCategoryData.name = '';
            this.tempSelectedCategoryData.order = null;
        },
        resetSelectedCategoryData() {
            this.selectedCategoryData.id = null;
            this.selectedCategoryData.name = '';
            this.selectedCategoryData.order = null;
        },
        async createOrEditCategory() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    if (this.createOrEditCategoryType === 'create') {
                        await this.doCreateNewCategory();
                    } else if (this.createOrEditCategoryType === 'edit') {
                        await this.doEditCategory();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doCreateNewCategory() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.createOrEditCategorySaveButtonLoading = true;
                    const paylods = { name: this.selectedCategoryData.name };
                    const response = await this.createJobPreferenceCategory(paylods);
                    if (response && response.status === 'OK') {
                        this.resetTempSelectedCategoryData();
                        this.resetSelectedCategoryData();
                        await window.UIkit.modal('#create-or-edit-category-modal-box').hide();
                        this.categoryListTableLoading = true;
                        await this.setCategory();
                        notificationSuccess('Category created successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                await window.UIkit.modal('#create-or-edit-category-modal-box').hide();
                notificationDanger(error);
            } finally {
                this.createOrEditCategorySaveButtonLoading = false;
                this.categoryListTableLoading = false;
            }
        },
        async doEditCategory() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.createOrEditCategorySaveButtonLoading = true;
                    const paylods = {
                        id: this.selectedCategoryData.id,
                        name: this.selectedCategoryData.name
                    };
                    const response = await this.editJobPreferenceCategory(paylods);
                    if (response && response.status === 'OK') {
                        this.resetTempSelectedCategoryData();
                        this.resetSelectedCategoryData();
                        await window.UIkit.modal('#create-or-edit-category-modal-box').hide();
                        this.categoryListTableLoading = true;
                        await this.setCategory();
                        notificationSuccess('Category updated successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                await window.UIkit.modal('#create-or-edit-category-modal-box').hide();
                notificationDanger(error);
            } finally {
                this.createOrEditCategorySaveButtonLoading = false;
                this.categoryListTableLoading = false;
            }
        },
        async doDeleteCategory() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.deleteCategoryButtonLoading = true;
                    const response = await this.deleteJobPreferenceCategory(this.selectedCategoryData.id);
                    if (response && response.status === 'OK') {
                        this.resetTempSelectedCategoryData();
                        this.resetSelectedCategoryData();
                        await window.UIkit.modal('#confirmation-delete-category-modal-box').hide();
                        this.categoryListTableLoading = true;
                        await this.setCategory();
                        notificationSuccess('Category deleted successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                await window.UIkit.modal('#confirmation-delete-category-modal-box').hide();
                notificationDanger(error);
            } finally {
                this.deleteCategoryButtonLoading = false;
                this.categoryListTableLoading = false;
            }
        },
        changeLimitMetaCategoryList(e) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.metaCategoryList = {
                    ...this.metaCategoryList,
                    limit: e.target.value
                };
            } else {
                this.internetConnection = false;
            }
        },
        changePageCategoryList(value) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.metaCategoryList = {
                    ...this.metaCategoryList,
                    page: value
                };
            } else {
                this.internetConnection = false;
            }
        },
        // endregion for category feature
        // region For job preference feature
        openFileThumbnail() {
            this.$refs.thumbnail.click();
        },
        openFileUpload() {
            this.$refs.upload_file.click();
        },
        async UploadThumbnail(e) {
            let files = this.$refs.thumbnail.files;
            const isFileOk = this.checkFile(files);
            if (isFileOk) {
                this.ThumbnailName = e.target.files[0].name;
                let formData = new FormData();
                for( let i = 0; i < files.length; i++) {
                    let file = files[i];
                    formData.set('file', file, file.fileName);
                    const result = await this.uploadImage({ folder: 'backoffice', category: 'job-preference-extra', data: formData });
                    this.selectedJobPreferenceData.image = result.file_url;
                }
            }
            e.target.value = null; // for reset uploaded same file/twice
        },
        async setJobPreference() {
            try {
                const response = await this.getJobPreferenceList(this.metaJobPreferenceList);
                if (response && response.status === 'OK') {
                    this.jobPreference = response.result;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async searchJobPreference() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.jobPreferenceListTableLoading = true;
                    this.metaJobPreferenceList.name = this.jobPreferenceName;
                    await this.setJobPreference();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.jobPreferenceListTableLoading = false;
            }
        },
        async showModalCreateNewJobPreference() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.resetTempSelectedJobPreferenceData();
                    this.resetSelectedJobPreferenceData();
                    this.createOrEditJobPreferenceType = 'create';
                    await window.UIkit.modal('#create-or-edit-job-preference-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        enableJobPreferenceReorderMode() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.jobPreferenceReorderMode = true;
            } else {
                this.internetConnection = false;
            }
        },
        async cancelReorderJobPreferenceList() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.cancelReorderJobPreferenceListButton = true;
                    this.jobPreferenceListTableLoading = true;
                    await this.setJobPreference();
                    this.jobPreferenceListTableLoading = false;
                    this.jobPreferenceReorderMode = false;
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.cancelReorderJobPreferenceListButton = false;
                this.jobPreferenceListTableLoading = false;
            }
        },
        async saveReorderJobPreferenceList(payload) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.saveReorderJobPreferenceListButton = true;
                    const response = await this.reorderJobPreferenceList(payload);
                    if (response && response.status === 'OK') {
                        this.jobPreferenceListTableLoading = true;
                        await this.setJobPreference();
                        this.jobPreferenceListTableLoading = false;
                        this.jobPreferenceReorderMode = false;
                        notificationSuccess('Job preference list reordered successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                this.saveReorderCategoryListButton = false;
                this.categoryListTableLoading = false;
                this.reorderMode = false;
                notificationDanger(error);
            } finally {
                this.saveReorderCategoryListButton = false;
                this.categoryListTableLoading = false;
                this.reorderMode = false;
            }
        },
        async showModalEditJobPreference(payload) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.resetTempSelectedJobPreferenceData();
                    this.resetSelectedJobPreferenceData();
                    await this.setCategoryValues();
                    this.createOrEditJobPreferenceType = 'edit';
                    if (payload) {
                        this.fillTempSelectedJobPreferenceData(payload);
                        this.fillSelectedJobPreferenceData(payload);
                    }
                    await window.UIkit.modal('#create-or-edit-job-preference-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showModalDeleteJobPreference(payload) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.resetTempSelectedJobPreferenceData();
                    this.resetSelectedJobPreferenceData();
                    await this.setCategoryValues();
                    if (payload) {
                        this.fillSelectedJobPreferenceData(payload);
                    }
                    await window.UIkit.modal('#confirmation-delete-job-preference-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showModalImportJobPreference(payload) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    if (payload) {
                        this.bulkUploadData.jobPreferenceName = payload.name;
                        this.bulkUploadData.jobPreferenceId = payload._id;
                    }
                    await window.UIkit.modal('#import-job-preference-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        fillTempSelectedJobPreferenceData(item) {
            this.tempSelectedJobPreferenceData.id = item.id;
            this.tempSelectedJobPreferenceData.name = item.name;
            this.tempSelectedJobPreferenceData.seo_url = item.seo_url;
            this.tempSelectedJobPreferenceData.job_preference_category_id = this.category_options.find(
                e => e._id === item.job_preference_category_id
            ) || {};
            this.tempSelectedJobPreferenceData.description = item.description;
            this.tempSelectedJobPreferenceData.order = item.order;
            this.tempSelectedJobPreferenceData.default = item.default;
            this.tempSelectedJobPreferenceData.image = item.image;
        },
        fillSelectedJobPreferenceData(item) {
            this.selectedJobPreferenceData.id = item.id;
            this.selectedJobPreferenceData.name = item.name;
            this.selectedJobPreferenceData.seo_url = item.seo_url;
            this.selectedJobPreferenceData.job_preference_category_id = this.category_options.find(
                e => e._id === item.job_preference_category_id
            ) || {};
            this.selectedJobPreferenceData.description = item.description;
            this.selectedJobPreferenceData.order = item.order;
            this.selectedJobPreferenceData.default = item.default;
            this.selectedJobPreferenceData.image = item.image;
        },
        resetTempSelectedJobPreferenceData() {
            this.ThumbnailName = 'Upload Image';
            this.tempSelectedJobPreferenceData.id = null;
            this.tempSelectedJobPreferenceData.name = '';
            this.tempSelectedJobPreferenceData.seo_url = '';
            this.tempSelectedJobPreferenceData.job_preference_category_id = {};
            this.tempSelectedJobPreferenceData.description = '';
            this.tempSelectedJobPreferenceData.order = null;
            this.tempSelectedJobPreferenceData.default = false;
            this.tempSelectedJobPreferenceData.image = null;
        },
        resetSelectedJobPreferenceData() {
            this.selectedJobPreferenceData.id = null;
            this.selectedJobPreferenceData.name = '';
            this.selectedJobPreferenceData.seo_url = '';
            this.selectedJobPreferenceData.job_preference_category_id = {};
            this.selectedJobPreferenceData.description = '';
            this.selectedJobPreferenceData.order = null;
            this.selectedJobPreferenceData.default = false;
            this.selectedJobPreferenceData.image = null;
        },
        async createOrEditJobPreference() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    if (this.createOrEditJobPreferenceType === 'create') {
                        await this.doCreateNewJobPreference();
                    } else if (this.createOrEditJobPreferenceType === 'edit') {
                        await this.doEditJobPreference();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doCreateNewJobPreference() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.createOrEditjobPreferenceSaveButtonLoading = true;
                    const paylods = {
                        name: this.selectedJobPreferenceData.name.trim(),
                        seo_url: this.selectedJobPreferenceData.seo_url.trim(),
                        job_preference_category_id: this.selectedJobPreferenceData.job_preference_category_id._id,
                        description: this.selectedJobPreferenceData.description.trim(),
                        default: this.selectedJobPreferenceData.default,
                        image: this.selectedJobPreferenceData.image,
                    };
                    const response = await this.createJobPreference(paylods);
                    if (response && response.status === 'OK') {
                        this.resetTempSelectedJobPreferenceData();
                        this.resetSelectedJobPreferenceData();
                        await window.UIkit.modal('#create-or-edit-job-preference-modal-box').hide();
                        this.jobPreferenceListTableLoading = true;
                        await this.setJobPreference();
                        this.jobPreferenceListTableLoading = false;
                        notificationSuccess('Job preference created successfully');
                        await this.$validator.reset();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                await window.UIkit.modal('#create-or-edit-job-preference-modal-box').hide();
                this.createOrEditjobPreferenceSaveButtonLoading = false;
                this.jobPreferenceListTableLoading = false;
                notificationDanger(error);
            } finally {
                this.createOrEditjobPreferenceSaveButtonLoading = false;
                this.jobPreferenceListTableLoading = false;
            }
        },
        async doEditJobPreference() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.createOrEditjobPreferenceSaveButtonLoading = true;
                    const paylods = {
                        id: this.selectedJobPreferenceData.id,
                        name: this.selectedJobPreferenceData.name.trim(),
                        seo_url: this.selectedJobPreferenceData.seo_url.trim(),
                        job_preference_category_id: this.selectedJobPreferenceData.job_preference_category_id._id,
                        description: this.selectedJobPreferenceData.description.trim(),
                        default: this.selectedJobPreferenceData.default,
                        image: this.selectedJobPreferenceData.image,
                    };
                    const response = await this.editJobPreference(paylods);
                    if (response && response.status === 'OK') {
                        this.resetTempSelectedJobPreferenceData();
                        this.resetSelectedJobPreferenceData();
                        await window.UIkit.modal('#create-or-edit-job-preference-modal-box').hide();
                        this.jobPreferenceListTableLoading = true;
                        await this.setJobPreference();
                        this.jobPreferenceListTableLoading = false;
                        this.createOrEditjobPreferenceSaveButtonLoading = false;
                        notificationSuccess('Job preference updated successfully');
                        await this.$validator.reset();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                await window.UIkit.modal('#create-or-edit-category-modal-box').hide();
                this.createOrEditjobPreferenceSaveButtonLoading = false;
                this.categoryListTableLoading = false;
                notificationDanger(error);
            } finally {
                this.createOrEditjobPreferenceSaveButtonLoading = false;
                this.categoryListTableLoading = false;
            }
        },
        async doDeleteJobPreference() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.deleteCategoryButtonLoading = true;
                    const response = await this.deleteJobPreference(this.selectedJobPreferenceData.id);
                    if (response && response.status === 'OK') {
                        this.resetTempSelectedJobPreferenceData();
                        this.resetSelectedJobPreferenceData();
                        await window.UIkit.modal('#confirmation-delete-job-preference-modal-box').hide();
                        this.jobPreferenceListTableLoading = true;
                        await this.setJobPreference();
                        this.jobPreferenceListTableLoading = false;
                        notificationSuccess('Job preference deleted successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                await window.UIkit.modal('#confirmation-delete-category-modal-box').hide();
                this.deleteCategoryButtonLoading = false;
                this.categoryListTableLoading = false;
                notificationDanger(error);
            } finally {
                this.deleteCategoryButtonLoading = false;
                this.categoryListTableLoading = false;
            }
        },
        changeLimitMetaJobPreferenceList(e) {
            this.metaJobPreferenceList = {
                ...this.metaJobPreferenceList,
                limit: e.target.value
            };
        },
        changePageJobPreferenceList(value) {
            this.metaJobPreferenceList = {
                ...this.metaJobPreferenceList,
                page: value
            };
        },
        // endregion job preference feature
        // region bulk upload
        async downloadTemplateJobPreference(item){
            const dataExcel = [];
            const obj = {
                "Job_Preference_Name": item.name,
                "Job_Preference_ID": item._id,
                "Job_Vacancy_ID": '',
                "Community_ID": '',
            };
            dataExcel.push(obj);
            excelDownloader(dataExcel, 'Bulk Upload Job Preference.xlsx');
            notificationSuccess('Downloaded!');
        },
        async createBulkUpload() {
            try {
                if (navigator.onLine) { // Check internet connection
                    const fileID = await excelReader(this.bulkUploadData.fileData);
                    const rowID = this.bulkUploadData.jobPreferenceId;
                    if (fileID !== rowID) {
                        notificationDanger('File tidak sesuai dengan job preference yang di pilih');
                        return false;
                    }
                    this.importJobPreferenceSaveButtonLoading = true;
                    this.internetConnection = true;
                    let data = new FormData();
                    data.append('excel', this.bulkUploadData.fileData);
                    const response = await this.createBulkUploadJobPreference(data);
                    if (response && response.status === 'OK') {
                        this.resetBulkUploadData();
                        await window.UIkit.modal('#import-job-preference-modal-box').hide();
                        this.importJobPreferenceSaveButtonLoading = false;
                        notificationSuccess('Bulk upload import sucessfully.');
                        await this.$validator.reset();
                    } else {
                        this.importJobPreferenceSaveButtonLoading = false;
                    }
                } else {
                    this.importJobPreferenceSaveButtonLoading = false;
                    this.internetConnection = false;
                }
            } catch (error) {
                this.importJobPreferenceSaveButtonLoading = false;
                notificationDanger(error);
            }
        },
        resetBulkUploadData() {
            this.bulkUploadData.jobPreferenceName = null;
            this.bulkUploadData.jobPreferenceId = null;
            this.bulkUploadData.fileData = null;
            this.UploadFileName = 'Upload File';
        },
        async UploadFile(e) {
            this.UploadFileName = e.target.files[0].name; // set name file
            this.bulkUploadData.fileData = e.target.files[0]; // set file format
            e.target.value = null; // for reset uploaded same file/twice
        },
    // endregion
    }
};
</script>

<style scoped>
.tab-menu {
  margin-top: 20px;
  border-top: 1px solid #E5E5E5;
  overflow-x: auto;
}
.tab-menu__button {
  padding: 15px 50px;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: none;
  color: #979797;
  font-size: 14px;
}
.tab-menu__button:hover {
  color: #000000;
}
.tab-menu__button-active {
  color: #000000;
  border-bottom: 2px solid #0275D8;
}
</style>
